import React, { useState, useEffect } from 'react';
import { Form, Button, Spin, Typography, Row, Col, Steps, Modal, message, Tag } from 'antd';
import LeadsSetupForm from './LeadsSetupForm';
import NewTaskModal from '../LeadsPage/NewTaskModal'; // Import NewTaskModal for the modal
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';
import useProfilePageHook from '../ProfilePage/ProfilePageHook';
import Notification from '../Notification/NotificationMessage';


import dayjs from 'dayjs';
import utc from 'dayjs-plugin-utc';
const { Title, Text } = Typography;
const { Step } = Steps;

const LeadsSetupPage = () => {
  const [form] = Form.useForm();
  const { updateBusinessContext, getBusinessContext, initiateSystemTask, pollSystemTasks } = useDocumentPageHook();
  const { generationCredit, dailyGenerationQuota, qualificationCredit, fetchUserProfile } = useProfilePageHook();
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false); // For showing the task modal
  const [taskSessionId, setTaskSessionId] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [companyQuestions, setCompanyQuestions] = useState([]);

  useEffect(() => {
    fetchAndSetBusinessContext();
  }, [form]);

  const setServices = (services) => {
    if (Array.isArray(services)) {
      // Returning the first object if it's an array or default values
      return services.length > 0 ? {
        serviceName: services[0].serviceName,
        serviceDescription: services[0].serviceDescription
      } : { serviceName: '', serviceDescription: '' };
    } else if (services && typeof services === 'object') {
      // Returning the object if it already matches the expected form structure
      return {
        serviceName: services.serviceName || '',
        serviceDescription: services.serviceDescription || ''
      };
    }
    // Return default values if no valid services data is found
    return { serviceName: '', serviceDescription: '' };
  };
  

  const fetchAndSetBusinessContext = async () => {
    setIsLoading(true);
    try {
      const response = await getBusinessContext();
      await fetchUserProfile();
      if (response && response.campaign) {
        const campaign = response.campaign;
        setCompanyQuestions(campaign.company_criteria.key_questions || [])
        
        form.setFieldsValue({
            name: campaign.name,
            minSize: campaign.company_criteria.companySize?.min,
            maxSize: campaign.company_criteria.companySize?.max,
            location: campaign.company_criteria.location,
            industry: campaign.company_criteria.industry,
            keywords: campaign.company_criteria?.keywords,
            foundedYear: campaign.company_criteria.founded_year,
            emailTemplate: campaign.company_criteria?.email_info?.emailTemplate,
            emailTemplateSubject: campaign.company_criteria?.email_info?.emailTemplateSubject,
            emailTemplateName: campaign.company_criteria?.email_info?.emailTemplateName,
            emailTemplateTitle: campaign.company_criteria?.email_info?.emailTemplateTitle,
            emailTemplateFollowupMessage: campaign.company_criteria?.email_info?.emailTemplateFollowupMessage,
            emailTemplateValueProp: campaign.company_criteria?.email_info?.emailTemplateValueProp,
            emailTemplateCompanyIntroduction: campaign.company_criteria?.email_info?.emailTemplateCompanyIntroduction,
            emailCallToAction: campaign.company_criteria?.email_info?.emailCallToAction,
            emailTemplateFunFacts: campaign.company_criteria?.email_info?.emailTemplateFunFacts,
            emailTemplateCustomerPainPoints: campaign.company_criteria?.email_info?.emailTemplateCustomerPainPoints,
            
            // Set keyQuestions with must, preferred, and moreInfo
            keyQuestions: unifyKeyQuestionsFormat(campaign.company_criteria.key_questions),
          
            // Set services
            services: setServices(campaign.company_criteria.services),

          
            // Need Funding
            needFunding: campaign.company_criteria.needFunding,
            lastFundingDate: campaign.company_criteria.lastFundingDate ? dayjs.utc(campaign.company_criteria.lastFundingDate) : null,
          
            // Contact Preferences
            contactPreference: campaign.contact_people_criteria.contact_preferences,
          
            // Set contactKeyQuestions with must, preferred, and moreInfo
            contactKeyQuestions: unifyKeyQuestionsFormat(campaign.contact_people_criteria.key_questions)
          });          

      }
    } catch (error) {
      console.error('Failed to fetch business context:', error);
      Notification.error('Failed to load existing information. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };  

  const getStepFields = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return ['services'];
      case 1:
        return ['name', 'location', 'industry', 'minSize', 'maxSize', 'keyQuestions'];
      case 2:
        return ['contactKeyQuestions'];
      case 3:
        return ['emailTemplateSubject', 'emailTemplateName', 'emailTemplateTitle', 'emailTemplateValueProp', 'emailCallToAction'];
      default:
        return [];
    }
  };

  const unifyKeyQuestionsFormat = (questionsData) => {
    // If questionsData is already in the new array format (check by looking for 'question_id' in the first item)
    if (Array.isArray(questionsData) && questionsData.length > 0) {
      // Check if the first item has a 'question_id' key
      if ('question_id' in questionsData[0]) {
        // Already in the desired format
        return questionsData;
      } else {
        // Add 'question_id' based on index to each question
        return questionsData.map((question, index) => ({
          ...question,
          question_id: index + 1  // Ensure question_id starts at 1, not 0
        }));
      }
    }
  
    // If it's in the old dictionary format, transform it to the new array format
    if (typeof questionsData === 'object' && questionsData !== null) {
      const unifiedQuestions = [];
  
      Object.entries(questionsData).forEach(([tag, questions]) => {
        questions.forEach(question => {
          if (question) {  // Ensure there's actually a question to push
            unifiedQuestions.push({
              tag: tag.toUpperCase(),  // Assuming old tags are lowercase and need to be converted to uppercase
              question: question,
              question_id: unifiedQuestions.length + 1  // Generate a new question_id sequentially
            });
          }
        });
      });
  
      return unifiedQuestions;
    }
  
    // If neither, return an empty array or some default
    return [];
  };
  
  const handleNext = async () => {
    try {
      // Validate only the fields for the current step
      const result = await form.validateFields(getStepFields(currentStep));
      setCurrentStep(currentStep + 1);
    } catch (error) {
      message.error(error.message || 'Please fill in all required fields.');
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmitForm = () => {
    form.validateFields().then(handleSaveAndInitiate).catch(handleValidationError);
  };  

  const handleSaveAndInitiate = async (values) => {
    const formData = new FormData();
    const name = values.name;
    const product_info = {
    };
    const email_info = {
      emailTemplate: values.emailTemplate,
      emailTemplateSubject: values.emailTemplateSubject,
      emailTemplateFollowupMessage: values.emailTemplateFollowupMessage,
      emailTemplateName: values.emailTemplateName,
      emailTemplateTitle: values.emailTemplateTitle,
      emailTemplateValueProp: values.emailTemplateValueProp,
      emailCallToAction: values.emailCallToAction,
      emailTemplateFunFacts: values.emailTemplateFunFacts,
      emailTemplateCompanyIntroduction: values.emailTemplateCompanyIntroduction,
      emailTemplateCustomerPainPoints: values.emailTemplateCustomerPainPoints,
    }
    const company_criteria = {
      companySize: {
        min: values.minSize,
        max: values.maxSize
      },
      services: values.services,
      keywords: values.keywords,
      location: values.location,
      industry: values.industry,
      founded_year: values.foundedYear,
      key_questions: values.keyQuestions,
      needFunding: values.needFunding,
      email_info: email_info,
      lastFundingDate: values.lastFundingDate ? values.lastFundingDate.toISOString() : null
    };
    const contact_people_criteria = {
      contact_preferences: values.contactPreference,
      key_questions: values.contactKeyQuestions,
    };
    const campaign = { name, product_info, company_criteria, contact_people_criteria };

    formData.append('campaign', JSON.stringify(campaign));

    try {
      await updateBusinessContext(formData);
      setShowTaskModal(true)
    } catch (error) {
      console.error('Failed to create business context:', error);
      Notification.error('Failed to save business context. Please try again.');
    }
  };

  const handleValidationError = (errorInfo) => {
    const errorMessage = errorInfo.errorFields?.map(field => `${field.name[0]}: ${field.errors[0]}`).join(', ');
    message.error(errorMessage);
  };  

  const handleInitiateTask = async (urlCount) => {
    const session_id = await initiateSystemTask(urlCount);
    setIsSubmitted(true)
    if (session_id) {
      pollSystemTasks(session_id);
    }
  };

  const steps = [
    'Service',
    'Keywords',
    'Contact',
    'Email',
  ];

  return (
    <div className="leads-setup-page">
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <div style={{
            width: '862px',
            margin: '0 auto',
            padding: '22px 66px',
            backgroundColor: '#fff',
            borderRadius: '16px',
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
          }}>
          <Row justify="space-between" align="middle" style={{ marginBottom: '20px' }}>
            <Col >
              <Title level={2}>Campaign Setup</Title>
            </Col>
            <Col >
              <Tag color="default" style={{ padding: '8px 16px' }}>
                <Text strong>Credits Left: {generationCredit}</Text>
              </Tag>
                <Button onClick={handlePrev} disabled={currentStep === 0} style={{ marginRight: '8px', marginTop: '15px' }}>
                  Previous
                </Button>
                {currentStep < steps.length - 1 ? (
                  <Button type="primary" onClick={handleNext} style={{width:120}}>
                    Next
                  </Button>
                ) : (
                  <Button type="primary" disabled={isSubmitted} onClick={handleSubmitForm}>
                    Save and Initiate
                  </Button>
                )}
            </Col>
          </Row>


          <Row justify="center">
            <Col>
              <Steps current={currentStep}>
                {steps.map((step, index) => (
                  <Step key={index} title={step} />
                ))}
              </Steps>
            </Col>
          </Row>


          <Form form={form} layout="vertical" style={{ marginTop: 32 }} onFinish={handleSubmitForm}>
            <LeadsSetupForm form={form} mode={true} currentStep={currentStep} companyQuestions={companyQuestions} />
            <div style={{ display: 'none' }}>
            <Form.Item name="keyQuestions" label="keyQuestions" rules={[{ required: false }]}>
            </Form.Item>   
            <Form.Item name="contactKeyQuestions" label="contactKeyQuestions" rules={[{ required: false }]}>
            </Form.Item>             
            </div>
          </Form>

          {/* Task Modal */}

          <NewTaskModal
            onInitiateTask={handleInitiateTask}
            maxURLCount={1000}
            dailyQuota={dailyGenerationQuota}
            isProcessing={false}
            outOfCredit={false}
            modalVisible={showTaskModal} // Modal visibility controlled by state
            setModalVisible={setShowTaskModal}
          />
        </div>
      )}
    </div>
  );
};

export default LeadsSetupPage;
