import React from 'react';
import { Form, Input, Select, Button, Typography, Tooltip, Card, Space, InputNumber, Tabs, DatePicker, Divider, Row, Col } from 'antd';
import { MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { decisionMakers, countries, industries, company_sizes } from './CompanyTargetOptions';

const { TextArea } = Input;
const { Title } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;


// Component for the middle form
const MiddleForm = ({ form, mode, isTask }) => (
  <div style={{ flex: 2, marginRight: '16px', marginTop: '0px' }}>
    <Form.Item
      name="name"
      label="Campaign name"
      rules={[{ required: true, message: "Name this campaign" }]}
    >
      <TextArea disabled={isTask || !mode} autoSize placeholder="" />
    </Form.Item>

    <Tabs defaultActiveKey="1" size='medium' centered>
      <TabPane tab="Services Provided" key="1" forceRender={true}>
        <Form.List name="services" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Card key={field.key} style={{ marginBottom: 16 }}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'serviceName']}
                    fieldKey={[field.fieldKey, 'serviceName']}
                    label={(
                      <>
                        Service Name {index + 1}
                        {index === 0 && (
                          <Tooltip title="Provide the name of the service you offer.">
                            <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                          </Tooltip>
                        )}
                      </>
                    )}
                    rules={[{ required: true, message: "Please provide the service name" }]}
                  >
                    <Input placeholder="E.g CRM Consulting" />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, 'serviceDescription']}
                    fieldKey={[field.fieldKey, 'serviceDescription']}
                    label="Service Description"
                    rules={[{ required: true, message: "Please provide the service description" }]}
                  >
                    <TextArea placeholder="Describe the service offering" />
                  </Form.Item>
                  {fields.length > 1 && mode && (
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  )}
                </Card>
              ))}
            </>
          )}
        </Form.List>
      </TabPane>

      <TabPane tab="Company Insights" key="2" forceRender={true}>
      <Form.List name={['keyQuestions', 'required']}>
        {(fields, { add, remove }) => (
          <>
              <Row justify="space-between" align="middle">
              <Col>
                  <Title level={4}>
                  Required key questions:
                  </Title>
              </Col>
              <Col>
                  {fields.length < 5 && (
                  <Button 
                      type="primary" 
                      onClick={() => add()} 
                      icon={<PlusOutlined />}
                      style={{marginTop:15}}
                      disabled={!mode || fields.length >= 5}>
                      Add a question
                  </Button>
                  )}
              </Col>
              </Row>    
            {fields.map((field, index) => (
              <Card key={field.key} style={{ marginBottom: 16 }}>
                <Form.Item
                  {...field}
                  name={[field.name]}
                  fieldKey={[field.fieldKey]}
                  label={`Filter Question ${index + 1}`}
                >
                  <Input placeholder={`E.g They are using salesforce`} disabled={!mode} />
                </Form.Item>
                {fields.length > 0 && mode && (
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                )}
              </Card>
            ))}
          </>
        )}
      </Form.List>

      <Divider />

        <Form.List name={['keyQuestions', 'preferred']}>
        {(fields, { add, remove }) => (
          <>
              <Row justify="space-between" align="middle">
              <Col>
                  <Title level={4}>
                  Preferred key questions:
                  </Title>
              </Col>
              <Col>
                  {fields.length < 5 && (
                  <Button 
                      type="primary" 
                      onClick={() => add()} 
                      icon={<PlusOutlined />}
                      style={{marginTop:15}}
                      disabled={!mode || fields.length >= 5}>
                      Add a question
                  </Button>
                  )}
              </Col>
              </Row>         
            {fields.map((field, index) => (
              <Card key={field.key} style={{ marginBottom: 16 }}>
                <Form.Item
                  {...field}
                  name={[field.name]}
                  fieldKey={[field.fieldKey]}
                  label={`Prefer Question ${index + 1}`}
                  rules={[{ required: false, message: "Please provide a question" }]}
                >
                  <Input placeholder={`E.g They have more than ${index + 1} engineers`} disabled={!mode} />
                </Form.Item>
                {fields.length > 0 && mode && (
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                )}
              </Card>
            ))}
          </>
        )}
      </Form.List>

      <Divider />

      {/* The company is FILTERED if */}

      {/* More information I'd like to learn about company */}
      </TabPane>

      <TabPane tab="Contact Insights" key="3" forceRender={true}>
      <Form.List name={['contactKeyQuestions', 'required']}>
          {(fields, { add, remove }) => (
            <>
              <Row justify="space-between" align="middle">
              <Col>
                  <Title level={4}>
                  Required Key Questions:
                  </Title>
              </Col>
              <Col>
                  {fields.length < 5 && (
                  <Button 
                      type="primary" 
                      onClick={() => add()} 
                      icon={<PlusOutlined />}
                      style={{marginTop:15}}
                      disabled={!mode || fields.length >= 5}>
                      Add a question
                  </Button>
                  )}
              </Col>
              </Row>           
              {fields.map((field, index) => (
                <Card key={field.key} style={{ marginBottom: 16 }}>
                  <Form.Item
                    {...field}
                    name={[field.name]}
                    fieldKey={[field.fieldKey]}
                    label={`Filter Question ${index + 1}`}
                  >
                    <Input placeholder={`e.g They have more than 10 years experience`} disabled={!mode} />
                  </Form.Item>
                  {fields.length > 0 && mode && (
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  )}
                </Card>
              ))}
            </>
          )}
        </Form.List>
        <Divider />


        <Form.List name={['contactKeyQuestions', 'preferred']}>
          {(fields, { add, remove }) => (
            <>
              <Row justify="space-between" align="middle">
              <Col>
                  <Title level={4}>
                  Preferred Key Questions:
                  </Title>
              </Col>
              <Col>
                  {fields.length < 5 && (
                  <Button 
                      type="primary" 
                      onClick={() => add()} 
                      icon={<PlusOutlined />}
                      style={{marginTop:15}}
                      disabled={!mode || fields.length >= 5}>
                      Add a question
                  </Button>
                  )}
              </Col>
              </Row>           
              {fields.map((field, index) => (
                <Card key={field.key} style={{ marginBottom: 16 }}>
                  <Form.Item
                    {...field}
                    name={[field.name]}
                    fieldKey={[field.fieldKey]}
                    label={`Prefer Question ${index + 1}`}
                    rules={[{ required: false, message: "Please provide a question" }]}
                  >
                    <Input placeholder={`e.g They are decision makers`} disabled={!mode} />
                  </Form.Item>
                  {fields.length > 0 && mode && (
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  )}
                </Card>
              ))}
            </>
          )}
        </Form.List>
    
        <Divider />
      </TabPane>
      <TabPane tab="Email" key="4" forceRender={true}>
        <EmailSetupForm form={form} mode={mode} visible={true} />
      </TabPane>
    </Tabs>
  </div>
);

const EmailSetupForm = ({ form, mode, visible }) => (
  <div style={{ display: visible ? 'block' : 'none' }}>
    <Form.Item
      name="emailTemplateSubject"
      label="Template Email Subject"
    >
      <Input placeholder="E.g., Hey [name], Interested in learning how Dealight works?" disabled={!mode} />
    </Form.Item>    
      <Form.Item
      name="emailTemplate"
      label="Template Email"
    >
      <Input.TextArea placeholder="Full email message" disabled={!mode} />
    </Form.Item>          

    <Form.List
      name="emailTemplateFollowupMessage"
    >
      {(fields, { add, remove }) => (
        <>
            <Row justify="space-between" align="middle">
            <Col>
                <Title level={4}>
                Template Follow up messages:
                </Title>
            </Col>
            <Col>
            {fields.length < 3 ? (
              <Button type="primary" onClick={() => add()} block icon={<PlusOutlined />} style={{marginTop:10}}>
                Add Follow up
              </Button>
            ) : null}
            </Col>
            </Row>          
          <Form.Item label="">
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Card key={key} style={{ marginBottom: 8 }}>
                <Form.Item
                  {...restField}
                  name={[name]}
                  fieldKey={[fieldKey]}
                >
                  <Input.TextArea placeholder="Follow up for this outreach message" disabled={!mode} rows={4} />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Card>
            ))}
          </Form.Item>
        </>
      )}
    </Form.List>    

  </div>
);

// Component for the right form
const RightForm = ({ form, mode }) => {
  const countries_options = countries.map(item => ({ value: item }));
  const industries_options = industries.map(item => ({ value: item }));
  const company_size_options = company_sizes.map(item => ({ value: item }));
  const filterOption = (input, option) =>
    (option?.value ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <div style={{ flex: 1 }}>
      <Form.Item
        label="Target Company's Size"
        rules={[{ required: true, message: "Please provide your target company's size" }]}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: 8 }}>From</span>
          <Form.Item
            name="minSize"
            rules={[{ required: true, message: 'Please input the minimum size!' }]}
            style={{ margin: 0, marginRight: 16 }}
            validateStatus={form.getFieldError('minSize').length ? 'error' : ''}
            help=""
          >
            <InputNumber min={1} placeholder="Min" style={{ width: '100%' }} disabled={!mode} />
          </Form.Item>
          <span style={{ marginRight: 8 }}>to</span>
          <Form.Item
            name="maxSize"
            rules={[{ required: true, message: 'Please input the maximum size!' }]}
            style={{ margin: 0 }}
            validateStatus={form.getFieldError('maxSize').length ? 'error' : ''}
            help=""
          >
            <InputNumber min={1} placeholder="Max" style={{ width: '100%' }} disabled={!mode} />
          </Form.Item>
        </div>
        <Form.ErrorList errors={form.getFieldError('minSize').concat(form.getFieldError('maxSize'))} />
      </Form.Item>
      <Form.Item
        name="location"
        label="Location"
        rules={[{ required: true, message: "Please provide the location of your target company" }]}
      >
        <Select
          placeholder="Select location"
          mode="multiple"
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          options={countries_options}
          disabled={!mode}
        />
      </Form.Item>
      <Form.Item
        name="industry"
        label="Industry"
        rules={[{ required: true, message: "Please provide the industry of your target company" }]}
      >
        <Select
          placeholder="Select industry"
          mode="multiple"
          allowClear
          filterOption={filterOption}
          options={industries_options}
          disabled={!mode}
        />
      </Form.Item>
      <Form.Item
          name="keywords"
          label="Keywords"
        >
          <Select
            mode="tags" // Allows users to enter free text
            placeholder="Enter keywords"
            allowClear
            disabled={!mode}
            tokenSeparators={[","]} // Allows separating keywords with commas
          />
        </Form.Item>      
      <Form.Item
        name="contactPreference"
        label="Contact Preference"
        rules={[{ required: true, message: "Please provide who you wish to connect" }]}
      >
        <Select
          mode="tags"
          placeholder="Select contact preference"
          options={decisionMakers}
          disabled={!mode}
        />
      </Form.Item>
    </div>
  );
};


const CompanyTargetForm = ({ form, mode, isTask, isImport }) => (
  <Row
    gutter={16}
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: isImport ? '120vh' : '0vh',
    }}
  >
    <Col span={isImport ? 24 : 18} style={{ flex: 2, padding: '24px', borderRadius: '8px', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}>
      <MiddleForm form={form} mode={mode} isTask={isTask}/>
    </Col>
    {!isImport && (
      <>
    <Divider type="vertical" style={{ height: 'auto', alignSelf: 'stretch' }} />
    <Col span={6} style={{ flex: 1, padding: '24px', borderRadius: '8px', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}>
      <RightForm form={form} mode={!isTask && mode} />
    </Col>
    </>
    )}
  </Row>
);

export default CompanyTargetForm;

